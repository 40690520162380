@mixin mb2 {
  margin-bottom: 1rem; }

@mixin mb3 {
  margin-bottom: 1.5rem; }

@mixin mt2 {
  margin-top: 1rem; }

@mixin mt4 {
  margin-top: 2rem; }
