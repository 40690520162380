@import "../base/colors";
@import '../base/margin';

.content {
  @include mt2; }

.fe.fe-check {
  color: $dashboard-green; }
.fas.fa-times {
  color: red; }
