$padding_base: 0.5rem;

@mixin p0 {
  padding: 0; }

@mixin p2 {
  padding: 2 * $padding_base; }

@mixin px1 {
  padding-left: 1 * $padding_base;
  padding-right: 1 * $padding_base; }

@mixin px2 {
  padding-left: 2 * $padding_base;
  padding-right: 2 * $padding_base; }

@mixin py1 {
  padding-bottom: 1 * $padding_base;
  padding-top: 1 * $padding_base; }

@mixin py2 {
  padding-bottom: 2 * $padding_base;
  padding-top: 2 * $padding_base; }
