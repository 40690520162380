// Pagination style impoted from http://mislav.github.io/will_paginate
@import "../base/colors";

.digg_pagination {
  background: white;
  cursor: default;
  padding: 1.5em;
  a, span, em {
    padding: 0.2em 0.5em;
    display: block;
    float: left;
    margin-right: 1px; }
  .disabled {
    color: #999999;
    border: 1px solid #dddddd; }
  .current {
    font-style: normal;
    font-weight: bold;
    background: $pagination-blue;
    color: white;
    border: 1px solid $pagination-blue; }
  a {
    text-decoration: none;
    color: #105cb6;
    border: 1px solid #9aafe5;
    &:hover, &:focus {
      color: #000033;
      border-color: #000033; } }
  .page_info {
    background: $pagination-blue;
    color: white;
    padding: 0.4em 0.6em;
    width: 22em;
    margin-bottom: 0.3em;
    text-align: center;
    b {
      color: #000033;
      background: $pagination-light-blue;
      padding: 0.1em 0.25em; } }
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden; }
  * html & {
    height: 1%; }
  *:first-child+html & {
    overflow: hidden; } }
