@import "../base/colors";

.sticky-header {
  position: sticky;
  top: 0;
  background: white; }

.table-scroll-sm {
  height: 450px;
  overflow: scroll; }

.table-td-align-middle td {
  vertical-align: middle; }
