// noUiSlider custom css
.noUi-handle {
  width: 16px !important;
  height: 16px !important;
  right: -9px !important;
  top: -6px;
  border-radius: 15px;
  background: #91c4f2;
  cursor: pointer;
  &:before {
    left: 0px !important;
    height: 0px !important; }
  &:after {
    left: 0px !important;
    height: 0px !important; } }

.noUi-connect {
  background: #91c4f2; }

.noUi-horizontal {
  height: 6px; }


.checkbox-btn {
  min-width: calc( 50% - 0.5rem );
  border: 1px solid #445877;
  &:hover {
    background-color: #445877;
    color: #ffffff; } }

.postal-code-item {
  display: inline-block;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 5px;
  margin-top: 5px;
  background-color: #e4f1fc;
  cursor: pointer;
  &:hover {
    background-color: #c8e2f9; } }

.postal-code-suggestion {
  display: inline-block;
  margin-right: 5px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 9999px;
  padding: 2px 5px 2px 5px;
  cursor: pointer;
  &:hover {
    background-color: #c8e2f9; } }

.checked {
  background-color: #052049 !important;
  color: #FFFFFF !important; }

.customer-need-input {
  input {
    height: 1.6rem;
    font-size: 0.7rem; }
  span {
    height: 1.6rem;
    font-size: 0.7rem; }
  label {
    font-size: 0.75rem; }
  select {
    font-size: 0.75rem; } }
