.table-customer-overview {
  tr:nth-child(even) {
 }    // background-color: #f4f4f469
  th {
    font-size: 0.75rem; }
  td {
    font-size: 0.75rem; }
  input {
    height: 1.6rem;
    font-size: 0.7rem; }
  span {
    height: 1.6rem;
    font-size: 0.7rem; } }

.submit-btn {
  line-height: 1;
  padding: 1px 1px 1px 1px; }

.input-customer-overview {
  input {
    height: 1.6rem;
    font-size: 0.7rem; }
  span {
    height: 1.6rem;
    font-size: 0.7rem; } }

.type-input-customer-overview {
  height: 1.6rem;
  font-size: 0.7rem;
  padding: 0px 0px 0px 8px; }

.total-costs-input {
  div {
    margin: 0px 0px 0px 0px; } }

.customer-overview-index-table {
  th {
    font-size: 0.75rem; }
  td {
    font-size: 0.75rem; } }
