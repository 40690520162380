@import "~bootstrap/scss/bootstrap";
@import '~tabler-ui/src/assets/scss/bundle';
@import '~flatpickr/dist/flatpickr.min';
@import '~flatpickr/dist/plugins/monthSelect/style';
@import '~selectize/dist/css/selectize';
@import '~dawa-autocomplete2/css/dawa-autocomplete2';

@import 'modules/content';
@import 'modules/cursor';
@import 'modules/dashboard';
@import 'modules/dialog';
@import 'modules/documents';
@import 'modules/fileupload';
@import 'modules/form_validation';
@import 'modules/forms';
@import 'modules/frontend_navbar';
@import 'modules/navigation_bar';
@import 'modules/notifications';
@import 'modules/pagination';
@import 'modules/orders';
@import 'modules/resource_list';
@import 'modules/tables';
@import 'modules/customer_overview';
@import 'modules/frontend_customer';
@import 'modules/service';
@import 'selectize';
@import 'tasks';
@import 'overrides';
$fa-font-path: '~@fortawesome/fontawesome-free-webfonts/webfonts';
@import '~@fortawesome/fontawesome-free-webfonts/scss/fontawesome';
@import '~@fortawesome/fontawesome-free-webfonts/scss/fa-solid';
@import '~nouislider/dist/nouislider';
@import 'modules/customer_need';
@import 'modules/tabler_override';
@import 'modules/cases';
@import "modules/flatpickr";
@import 'modules/document_analysis_spinner';
