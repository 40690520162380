@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~tabler-ui/src/assets/scss/variables';

.card-header-tabs {
  line-height: inherit; }

.card-status-bottom {
  top: auto;
  bottom: 0;
  border-radius: 0 0 $card-border-radius $card-border-radius; }

.nav-tabs .nav-link.active {
  color: $primary; }

.text-decoration-none {
  text-decoration: none !important; }

.text-wrap-none {
  white-space: normal; }

.transition-none {
  transition: none !important; }

.w-10 {
  width: 10%; }

@keyframes rotate {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.bootstrap-autocomplete {
  & + .input-icon-addon {
    .fe-refresh-cw {
      animation: rotate 2s linear infinite; } }

  .dropdown-item {
    &.active {
      @include gradient-bg($gray-200); } // $dropdown-link-hover-bg

    &.disabled:not(:last-child) {
      @include nav-divider($dropdown-divider-bg);
      padding: 0;

      &:first-child {
        display: none; } } } }
