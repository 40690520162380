@import '~noty/src/noty.scss';
@import '~noty/src/themes/bootstrap-v4.scss';

// Notifications
//
// Showing alerts and flash messages to the user using Noty
//
// See https://ned.im/noty for further details

// Don't show the regular HTML flash messages
.alert {
  display: none; }

// Slide down from above
.noty_effects_open {
  transform: translate(0, -100px); }

// Place the notifications slightly into/over the navbar
#noty_layout__topCenter {
  top: 2rem; }

// Go up when going away
@keyframes noty_anim_out {
  100% {
    transform: translate(0, -100px);
    opacity: 0; } }
