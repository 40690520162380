@import "../base/colors";

// current_agreements styling
.dashboard {
  background-color: $dashboard-dark-blue;
  @media (min-width: 1600px) {
    .container {
      max-width: 1500px; } }
  @media (min-width: 1900px) {
    .container {
      max-width: 1800px; } }
  .card-table-dashboard {
    .i.fe {
      font-weight: bold;
      font-size: 20px; }
    .i.fe.fe-circle {
      color: $dashboard-turquoise; }
    .i.fe.fe-check {
      color: $dashboard-green; }
    .dashboard-header {
      color: white; }
    td {
      border: none;
      padding : 8px;
      font-size: 15px; }
    tr {
      color: $dashboard-grey; }
    th {
      font-size: 17px; } }
  .card-table-dashboard-small {
    td {
      border: none;
      padding : 8px;
      font-size: 13px; }
    tr {
      color: $dashboard-grey;
      border-bottom: 0.5px dashed #ffffff0f; }
    th {
      font-size: 15px; } } }
.dashboard-secondry-header {
  color: white; }

.red-border {
  border-top: .5px dashed #a71313; }

.grey-border {
  border-top: 0.5px dashed #ffffff0f; }

// completed_customers_month styling
.card-product {
  background: #ffffff05;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  .card {
    background: #ffffff05;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    color: #9c9c9c; } }
.logo {
  color: #9aa0ac;
  margin: 3px;
  font-size: 32px;
  padding: 10px 0; }
.medium-logo {
  color: #9aa0ac;
  margin: 3px;
  font-size: 28px;
  padding: 10px 0; }
.small-logo {
  margin-top: 31px; }
.total-number {
  color: white;
  font-size: 24px; }

// dashboard_target styling
.circle-chart__circle {
  animation: circle-chart-fill 0s reverse;
  transform: rotate(-90deg);
  transform-origin: center; }
.circle-chart__info {
  animation: circle-chart-appear 0s forwards;
  transform: translateY(0.3em); }

@keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100; } }

@keyframes circle-chart-appear {
  to {
    transform: translateY(0); } }

// performance styling
.logo-performance {
  color: #9aa0ac;
  font-size: 16px;
  margin-right: 8px;
  padding-top: 8px;
  padding-left: 19px; }
.total-number-performance {
  color: white;
  font-size: 20px;
  margin: 10px; }
.text-performace {
  font-size: 12px;
  padding-right: 24px; }
.table-performance {
  color: $dashboard-grey;
  td {
    border-top: 0.5px dashed #ffffff0f; }
  th {
    font-weight: 600; } }
// performance calender form styling
.dashboard-form {
  .form-group {
    .form-control {
      background-color: transparent;
      border: 1px solid #868e96;
      border-radius: 6px;
      color: white;
      text-align: center; }
    .form-label {
      color: grey; } } }
