@import '../base/colors';
@import '../base/margin';
@import '../base/padding';

// Dialog
//
// Modal-style popups or full screen dialog boxes.
.dialog {}

body.dialog {
  background: #f1f2f4; }

.dialog-modal {
  $_width: 20em;

  @include p2;

  left: 50%;
  max-width: $_width;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -60%);
  width: 95%;

  > h1 {
    @include mb3;
    @include mt2; }

  .button {
    @include mt4; }

  .form-control-label {
    text-align: left; }

  .flash-message {
    @include mb2; } }
