@import "../base/colors";

.bank-orders-table {
  font-size: 13px;
  table, td {
    padding: 0.5rem; } }
.header-name {
  color: $dashboard-grey;
  font-weight: 100; }
.order-header {
  margin: 30px 5px;
  padding-bottom: 5px; }
.style-header {
  border-bottom: 3px solid #495057;
  font-weight: bold; }
.card-table-order {
  th {
    font-weight: bold; } }
.order-price {
  font-weight: bold; }
