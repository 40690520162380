@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Functions
@function add($value1, $value2, $return-calc: true) {
  @if $value1 == null {
    @return $value2; }
  @if $value2 == null {
    @return $value1; }
  @if type-of($value1) == number and type-of($value2) == number and comparable($value1, $value2) {
    @return $value1 + $value2; }
  @return if($return-calc == true, calc(#{$value1} + #{$value2}), $value1 + unquote(" + ") + $value2); }

@function escape-svg($string) {
  @if str-index($string, "data:image/svg+xml") {
    @each $char, $encoded in (("<","%3c"), (">","%3e"), ("#","%23")) {
      $string: str-replace($string, $char, $encoded); } }
  @return $string; }


// Mixins
@mixin form-validation-state-with-icon($state, $color, $icon) {
  @include form-validation-state($state, $color);

  .form-control {
    &.is-#{$state} {
      border-color: $color;

      padding-right: $input-height-inner;
      background-image: escape-svg($icon);
      background-repeat: no-repeat;
      background-position: right add($input-line-height * .25em, $input-padding-y / 2) center;
      background-size: add($input-line-height * .5em, $input-padding-y);

      &:focus {
        border-color: $color;
        box-shadow: 0 0 0 $input-focus-width rgba($color, .25); }

      ~ .#{$state}-feedback, ~ .#{$state}-tooltip {
        display: block; } } } }


// Variables
$form-feedback-warning-color:      theme-color("warning") !default;
$form-feedback-icon-warning-color: $form-feedback-warning-color !default;
$form-feedback-icon-warning:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='#{$form-feedback-warning-color}' d='M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z'/><path fill='#{$form-feedback-warning-color}' d='M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z'/></svg>") !default;
@include form-validation-state-with-icon('warning', $form-feedback-warning-color, $form-feedback-icon-warning);
