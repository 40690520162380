// Fileupload module
//
// Primarily styles for styling the Dropzone provided classes
@import "~tabler-ui/src/assets/scss/variables";
@import "@uppy/dashboard/dist/style.css";

@import "../base/margin";

.dz-clickable {
  @include mt2;
  border-color: $border-color;
  border-radius: 1em;
  border-style: dashed;
  height: 33vh;
  min-height: 13em; }

.dz-drag-hover {
  background: $table-hover-bg; }
