// Ref: https://developer.mozilla.org/en-US/docs/Web/CSS/cursor
$cursors: (
  // General
  auto, default, none,
  // Links & status
  context-menu, help, pointer, progress, wait,
  // Selection
  cell, crosshair, text, vertical-text,
  // Drag & drop
  alias, copy, move, no-drop, not-allowed, grab, grabbing,
  // Resizing & scrolling
  all-scroll, col-resize, row-resize, n-resize, e-resize, s-resize, w-resize, ne-resize, nw-resize, se-resize, sw-resize, ew-resize, ns-resize, nesw-resize, nwse-resize,
  // Zooming
  zoom-in, zoom-out
);

@each $cursor in $cursors {
  .cursor-#{$cursor} {
    cursor: $cursor;
  }
}
