.card.hover-effect {
  flex: 0 0 calc(50% - 20px);
  margin: 10px;
  transition: transform 0.3s; }


.card.hover-effect:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer; }
