@media screen and (max-width: 720px) {
  .mobile-mr-5 {
    margin-right: 5px; }
  .mobile-ml-5 {
    margin-left: 5px; }
  .mobile-mt-20 {
    margin-top: 20px; } }


#copyButton.clicked {
  background-color: green;
  color: white;
  border-color: green; }

