@import "../base/colors";

th.document-sort {
    cursor: pointer;
    &:hover, &:focus {
      color: $pale-sky; } }

table.card-table {
  tr:nth-child(even) {
    background-color: #f4f4f469; } }
