@import "../base/colors";
@import "../base/margin";
@import "../base/padding";

.navigation-bar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: flex-end;

  @include mb2;
  .input-icon {
    margin-top: -5px;
    margin-bottom: -5px;
    .fe.fe-search {
      font-size: 15px;
      vertical-align: middle; }
    button {
      background: transparent;
      border: 0;
      color: rgba($pale-sky, 0.75);
      display: block;
      height: 38px;
      left: 163px;
      line-height: 31px;
      position: absolute;
      top: 0px;
      width: 38px;

      &:hover {
        color: $midnight-blue; } } } }

.navigation-bar-back {
  flex-basis: 15%;
  margin-right: 0;
  order: 0;
  flex-basis: 50%;
  order: 0; }

.navigation-bar-forward {
  display: flex;
  flex-basis: 15%;
  justify-content: flex-end;
  order: 2;
  flex-basis: 50%;
  order: 1; }

.navigation-bar-item {
  margin: 0;

  display: inline-block;
  font-size: 1em;
  text-decoration: none;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: inherit; }

  @include p0;
  @include py1;
  @include px1;
  .small-muted {
    color: #9aa0ac;
    font-size: 14px;
    font-weight: 400; } }

.navigation-bar-title {
  flex-basis: 70%;
  order: 1;
  text-align: center;
  flex-basis: 100%;
  flex-shrink: 0;
  font-size: 2em;
  order: 2;
  text-align: left; }

.nav-tabs {
  .nav-link.active {
    color: #ffffff; }
  .nav-link.no-border {
    border: 0; } }
.nav-pills {
  .nav-link.active {
    background-color: transparent; } }

.nav-item-vertical {
  border: 0 !important;
  width: 100%;
  &:hover {
    background: #e8e8e8;
    transition: 0.2s; } }

.nav-item-hover {
  &:hover {
    background: #e8e8e8;
    transition: 0.2s; } }
